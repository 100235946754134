import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "../styles/Contact.css"
import circlepng from "../images/circlepng.png"
import { Helmet } from "react-helmet"

export default function Terms() {
  return (
    <div>
      <Helmet>
        <title>
          Terms | ReAim - Reach your audience with push notifications
        </title>
      </Helmet>
      <Navigation />
      <div>
        <div className="contact-container">
          <div className="pricing-header rwd-pricing-header">
            <h1>Terms and Conditions</h1>
          </div>
          <div className="box-info-container privacy-policy-grid">
            <div className="contact-box terms-box">
              <div className="pricing-header pricing-header-text">
                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">1.</p>
                  <p className="privacy-policy-underline">Introduction</p>
                </div>
                <p>
                  Welcome to{" "}
                  <span className="privacy-policy-bold">
                    OPTINIAN LTD(“Company”, “we”, “our”, “us”)
                  </span>
                  ! As you have just clicked our Terms of Service, please pause,
                  grab a cup of coffee and carefully read the following pages.
                  It will take you approximately 20 minutes.
                </p>

                <p>
                  These Terms of Service
                  <span className="privacy-policy-bold">
                    (“Terms”, “Terms of Service”)
                  </span>
                  govern your use of our web pages located at{" "}
                  <a href="https://reaim.meoperated">https://reaim.me</a>{" "}
                  operated by OPTINIAN LTD.
                </p>

                <p>
                  Our privacy Policy also governs your use of our Service and
                  explains how we collect, safeguard and disclose information
                  that results from your use of our web pages. Please read it
                  here{" "}
                  <a href="https://reaim.me/privacyPolicy">
                    https://reaim.me/privacyPolicy
                  </a>
                </p>

                <p>
                  Your agreement with us includes these Terms and our Privacy
                  Policy{" "}
                  <span className="privacy-policy-bold">(“Agreements”)</span>.
                  You acknowledge that you have read and understood Agreements,
                  and agree to be bound of them.
                </p>

                <p>
                  If you do not agree with (or cannot comply with) Agreements,
                  then you may not use the Service, but please let us know by
                  emailing at support@reaim.me so we can try to find a solution.
                  These Terms apply to all visitors, users and others who wish
                  to access or use Service
                </p>

                <p>Thank you for being responsible</p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">2.</p>
                  <p className="privacy-policy-underline">Communications</p>
                </div>
                <p>
                  By creating an Account on our Service, you agree to subscribe
                  to newsletters, marketing or promotional materials and other
                  information we may send. However, you may opt out of receiving
                  any, or all, of these communications from us by following the
                  unsubscribe link or by emailing at.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">3.</p>
                  <p className="privacy-policy-underline">Purchases</p>
                </div>

                <p>
                  If you wish to purchase any product or service made available
                  through Service{" "}
                  <span className="privacy-policy-bold">(“Purchase”)</span>, you
                  may be asked to supply certain information relevant to your
                  Purchase including, without limitation, your credit card
                  number, the expiration date of your credit card, your billing
                  address, and your shipping information.
                </p>

                <p>
                  You represent and warrant that: (i) you have the legal right
                  to use any credit card(s) or other payment method(s) in
                  connection with any Purchase; and that (ii) the information
                  you supply to us is true, correct and complete.
                </p>

                <p>
                  We may employ the use of third party services for the purpose
                  of facilitating payment and the completion of Purchases. By
                  submitting your information, you grant us the right to provide
                  the information to these third parties subject to our Privacy
                  Policy.
                </p>

                <p>
                  We reserve the right to refuse or cancel your order at any
                  time for reasons including but not limited to: product or
                  service availability, errors in the description or price of
                  the product orservice, error in your order or other reasons.
                </p>

                <p>
                  We reserve the right to refuse or cancel your order if fraud
                  or an unauthorized or illegal transaction is suspected.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">4.</p>
                  <p className="privacy-policy-underline">
                    Contests, Sweepstakes and Promotions
                  </p>
                </div>

                <p>
                  Any contests, sweepstakes or other promotions (collectively,
                  <span className="privacy-policy-bold"> “Promotions”)</span>
                  made available through Service may be governed by rules that
                  are separate from these Terms of Service. If you participate
                  in any Promotions, please review the applicable rules as well
                  as our Privacy Policy. If the rules for a Promotion conflict
                  with these Terms of Service, Promotion rules will apply.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">5.</p>
                  <p className="privacy-policy-underline">Subscriptions</p>
                </div>
                <p>
                  Some parts of Service are billed on a subscription basis
                  <span className="privacy-policy-bold">
                    {" "}
                    (“Subscription(s)”)
                  </span>
                  . You will be billed in advance on a recurring and periodic
                  basis{" "}
                  <span className="privacy-policy-bold">(“Billing Cycle”)</span>
                  . Billing cycles are set either on a monthly or annual basis,
                  depending on the type of subscription plan you select when
                  purchasing a Subscription.
                </p>

                <p>
                  At the end of each Billing Cycle, your Subscription will
                  automatically renew under the exact same conditions unless you
                  cancel it or OPTINIAN LTD cancels it. You may cancel your
                  Subscription renewal either through your online account
                  management page or by contacting OPTINIAN LTD customer support
                  team.
                </p>

                <p>
                  A valid payment method, including credit card or PayPal, is
                  required to process the payment for your subscription. You
                  shall provide OPTINIAN LTD with accurate and complete billing
                  information including full name, address, state, zip code,
                  telephone number, and a valid payment method information. By
                  submitting such payment information, you automatically
                  authorize OPTINIAN LTD to charge all Subscription fees
                  incurred through your account to any such payment instruments.
                </p>

                <p>
                  Should automatic billing fail to occur for any reason,
                  OPTINIAN LTD will issue an electronic invoice indicating that
                  you must proceed manually, within a certain deadline date,
                  with the full payment corresponding to the billing period as
                  indicated on the invoice.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">6.</p>
                  <p className="privacy-policy-underline">Fee Changes</p>
                </div>

                <p>
                  OPTINIAN LTD, in its sole discretion and at any time, may
                  modify Subscription fees for the Subscriptions. Any
                  Subscription fee change will become effective at the end of
                  the then-current Billing Cycle.
                </p>

                <p>
                  OPTINIAN LTD will provide you with a reasonable prior notice
                  of any change in Subscription fees to give you an opportunity
                  to terminate your Subscription before such change becomes
                  effective.
                </p>

                <p>
                  Your continued use of Service after Subscription fee change
                  comes into effect constitutes your agreement to pay the
                  modified Subscription fee amount.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">7.</p>
                  <p className="privacy-policy-underline">Refunds</p>
                </div>

                <p>
                  Except when required by law, paid Subscription fees are
                  non-refundable.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">8.</p>
                  <p className="privacy-policy-underline">Content</p>
                </div>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">9.</p>
                  <p className="privacy-policy-underline">Prohibited Uses</p>
                </div>

                <p>
                  You may use Service only for lawful purposes and in accordance
                  with Terms. You agree not to use Service:
                  <br /> <br />
                  (a) In any way that violates any applicable national or
                  international law or regulation. <br /> <br />
                  (b) For the purpose of exploiting, harming, or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content or otherwise. <br /> <br />
                  (c)To transmit, or procure the sending of, any advertising or
                  promotional material, including any “junk mail”, “chain
                  letter,” “spam,” or any other similar solicitation.
                  <br /> <br />
                  (d) To impersonate or attempt to impersonate Company, a
                  Company employee, another user, or any other person or entity.
                  <br />
                  <br />
                  (e) In any way that infringes upon the rights of others, or in
                  any way is illegal, threatening, fraudulent, or harmful, or in
                  connection with any unlawful, illegal, fraudulent, or harmful
                  purpose or activity. <br /> <br />
                  (f) To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of Service, or which, as determined
                  by us, may harm or offend Company or users of Service or
                  expose them to liability.
                </p>
                <p>
                  Additionally, you agree not to:
                  <br /> <br />
                  (a) Use Service in any manner that could disable, overburden,
                  damage, or impair Service or interfere with any other party’s
                  use of Service, including their ability to engage in real time
                  activities through Service.
                  <br /> <br />
                  (b) Use any robot, spider, or other automatic device, process,
                  or means to access Service for any purpose, including
                  monitoring or copying any of the material on Service. <br />
                  <br />
                  (c) Use any manual process to monitor or copy any of the
                  material on Service or for any other unauthorized purpose
                  without our prior written consent.
                  <br /> <br />
                  (d) Use any device, software, or routine that interferes with
                  the proper working of Service.
                  <br />
                  <br />
                  (e) Introduce any viruses, trojan horses, worms, logic bombs,
                  or other material which is malicious or technologically
                  harmful. <br /> <br />
                  (f) Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of Service, the server on which
                  Service is stored, or any server, computer, or database
                  connected to Service. <br /> <br />
                  (g) Attack Service via a denial-of-service attack or a
                  distributed denial-of-service attack. <br /> <br />
                  (h) Take any action that may damage or falsify Company rating.
                  <br /> <br />
                  (i) Otherwise attempt to interfere with the proper working of
                  Service.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">10.</p>
                  <p className="privacy-policy-underline">Analytics</p>
                </div>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>

                <span className="privacy-policy-bold">Google Analytics</span>
                <p className="privacy-policy-p-margin">
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualise and personalise the ads of its
                  own advertising network.
                </p>

                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  <a href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>

                <p>
                  We also encourage you to review the Google's policy for
                  safeguarding your data:{" "}
                  <a href="https://support.google.com/analytics/answer/6004245">
                    https://support.google.com/analytics/answer/6004245
                  </a>
                </p>

                <span className="privacy-policy-bold">
                  Cloudflare analytics
                </span>
                <p className="privacy-policy-p-margin">
                  Cloudflare analytics is a web analytics service operated by
                  Cloudflare Inc. Read the Privacy Policy here:
                  <a href="https://www.cloudflare.com/privacypolicy/">
                    https://www.cloudflare.com/privacypolicy/
                  </a>
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">11.</p>
                  <p className="privacy-policy-underline">No Use By Minors</p>
                </div>

                <p>
                  Service is intended only for access and use by individuals at
                  least eighteen (18) years old. By accessing or using any of
                  Company, you warrant and represent that you are at least
                  eighteen (18) years of age and with the full authority, right,
                  and capacity to enter into this agreement and abide by all of
                  the terms and conditions of Terms. If you are not at least
                  eighteen (18) years old, you are prohibited from both the
                  access and usage of Service.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">12.</p>
                  <p className="privacy-policy-underline">Accounts</p>
                </div>

                <p>
                  When you create an account with us, you guarantee that you are
                  above the age of 18, and that the information you provide us
                  is accurate, complete, and current at all times. Inaccurate,
                  incomplete, or obsolete information may result in the
                  immediate termination of your account on Service.
                </p>

                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password, including but not limited to the
                  restriction of access to your computer and/or account. You
                  agree to accept responsibility for any and all activities or
                  actions that occur under your account and/or password, whether
                  your password is with our Service or a third-party service.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorized use of your account.
                </p>

                <p>
                  You may not use as a username the name of another person or
                  entity or that is not lawfully available for use, a name or
                  trademark that is subject to any rights of another person or
                  entity other than you, without appropriate authorization. You
                  may not use as a username anyname that is offensive, vulgar or
                  obscene.
                </p>

                <p>
                  We reserve the right to refuse service, terminate accounts,
                  remove or edit content, or cancel orders in our sole
                  discretion.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">13.</p>
                  <p className="privacy-policy-underline">
                    Intellectual Property
                  </p>
                </div>

                <p>
                  Service and its original content (excluding Content provided
                  by users), features and functionality are and will remain the
                  exclusive property of OPTINIAN LTD and its licensors.Service
                  is protected by copyright, trademark, and other laws of
                  foreign countries. Our trademarks and trade dress may not be
                  used in connection with any product or service without the
                  prior written consent of OPTINIAN LTD.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">14.</p>
                  <p className="privacy-policy-underline">Copyright Policy</p>
                </div>

                <p>
                  We respect the intellectual property rights of others. It is
                  our policy to respond to any claim that Content posted on
                  Service infringes on the copyright or other intellectual
                  property rights{" "}
                  <span className="privacy-policy-bold">(“Infringement”)</span>{" "}
                  of any person or entity.
                </p>

                <p>
                  If you are a copyright owner, or authorized on behalf of one,
                  and you believe that the copyrighted work has been copied in a
                  way that constitutes copyright infringement, please submit
                  your claim via email to dmca@reaim.me, with the subject line:
                  “Copyright Infringement” and include in your claim a detailed
                  description of the alleged Infringement as detailed below,
                  under “DMCA Notice and Procedure for Copyright Infringement
                  Claims”
                </p>

                <p>
                  You may be held accountable for damages (including costs and
                  attorneys' fees) for misrepresentation or bad-faith claims on
                  the infringement of any Content found on and/or through
                  Service on your copyright.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">15.</p>
                  <p className="privacy-policy-underline">
                    DMCA Notice and Procedure for Copyright Infringement Claims
                  </p>
                </div>

                <p>
                  You may submit a notification pursuant to the Digital
                  Millennium Copyright Act (DMCA) by providing our Copyright
                  Agent with the following information in writing (see 17 U.S.C
                  512(c)(3) for further detail):
                </p>

                <p>
                  (a) an electronic or physical signature of the person
                  authorized to act on behalf of the owner of the copyright's
                  interest;
                  <br /> <br />
                  (b) a description of the copyrighted work that you claim has
                  been infringed, including the URL (i.e., web page address) of
                  the location where the copyrighted work exists or a copy of
                  the copyrighted work;
                  <br /> <br />
                  (c) identification of the URL or other specific location on
                  Service where the material that you claim is infringing is
                  located;
                  <br /> <br />
                  (d) your address, telephone number, and email address;
                  <br />
                  <br />
                  (e) a statement by you that you have a good faith belief that
                  the disputed use is not authorized by the copyright owner, its
                  agent, or the law; <br /> <br />
                  (f) a statement by you, made under penalty of perjury, that
                  the above information in your notice is accurate and that you
                  are the copyright owner or authorized to act on the copyright
                  owner's behalf.
                </p>

                <p>
                  You can contact our Copyright Agent via email at dmca@reaim.me
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">16.</p>
                  <p className="privacy-policy-underline">
                    Error Reporting and Feedback
                  </p>
                </div>
                <p>
                  You may provide us directly at support@reaim.me with
                  information and feedback concerning errors, suggestions for
                  improvements, ideas, problems, complaints, and other matters
                  related to our Service{" "}
                  <span className="privacy-policy-bold">(“Feedback”)</span>. You
                  acknowledge and agree that: (i) you shall not retain, acquire
                  or assert any intellectual property right or other right,
                  title or interest in or to the Feedback; (ii) Company may have
                  development ideas similar to the Feedback; (iii) Feedback does
                  not contain confidential information or proprietary
                  information from you or any third party; and (iv) Company is
                  not under any obligation of confidentiality with respect to
                  the Feedback. In the event the transfer of the ownership to
                  the Feedback is not possible due to applicable mandatory laws,
                  you grant Company and its affiliates an exclusive,
                  transferable, irrevocable, free-of-charge, sub-licensable,
                  unlimited and perpetual right to use (including copy, modify,
                  create derivative works, publish, distribute and
                  commercialize) Feedback in any manner and for any purpose.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">17.</p>
                  <p className="privacy-policy-underline">
                    Links To Other Web Sites
                  </p>
                </div>

                <p>
                  Our Service may contain links to third party web sites or
                  services that are not owned or controlled by OPTINIAN LTD.
                </p>

                <p>
                  OPTINIAN LTD has no control over, and assumes no
                  responsibility for the content, privacy policies, or practices
                  of any third party web sites or services. We do not warrant
                  the offerings of any of these entities/individuals or their
                  websites.
                </p>

                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT OPTINIAN LTD SHALL NOT BE
                  RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE
                  OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION
                  WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                  AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
                  SERVICES.
                </p>

                <p>
                  WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                  PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT
                  YOU VISIT.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">18.</p>
                  <p className="privacy-policy-underline">
                    Disclaimer Of Warranty
                  </p>
                </div>

                <p>
                  THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR
                  WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                  OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR
                  MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE
                  OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
                  OBTAINED FROM US IS AT YOUR SOLE RISK.
                </p>

                <p>
                  NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES
                  ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                  COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                  AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
                  NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS
                  OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES
                  OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
                  RELIABLE, ERROR-FREE, OR UNINTERRUPTED,THAT DEFECTS WILL BE
                  CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
                  AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
                  THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                  THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                </p>

                <p>
                  COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                  LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                  NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                </p>

                <p>
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">19.</p>
                  <p className="privacy-policy-underline">
                    Limitation Of Liability
                  </p>
                </div>

                <p>
                  EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                  OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                  INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                  DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
                  RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                  AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                  ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
                  NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                  CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION
                  ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM
                  THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE,
                  OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
                  COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY
                  FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
                  PAID FOR THE PRODUCTS AND/OR SERVICES,AND UNDER NO
                  CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
                  SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
                  LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">20.</p>
                  <p className="privacy-policy-underline">Termination</p>
                </div>

                <p>
                  We may terminate or suspend your account and bar access to
                  Service immediately, without prior notice or liability, under
                  our sole discretion, for any reason whatsoever and without
                  limitation, including but not limited to a breach of Terms.
                </p>

                <p>
                  If you wish to terminate your account, you may simply
                  discontinue using Service.
                </p>

                <p>
                  All provisions of Terms which by their nature should survive
                  termination shall survive termination, including, without
                  limitation, ownership provisions, warranty disclaimers,
                  indemnity and limitations of liability.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">21.</p>
                  <p className="privacy-policy-underline">Governing Law</p>
                </div>

                <p>
                  These Terms shall be governed and construed in accordance with
                  the laws ofthe United Kingdom without regard to its conflict
                  of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms
                  will not be considered a waiver of those rights. If any
                  provision of these Terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  Terms will remain in effect. These Terms constitute the entire
                  agreement between us regarding our Service and supersede and
                  replace any prior agreements we might have had between us
                  regarding Service.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">22.</p>
                  <p className="privacy-policy-underline">Changes To Service</p>
                </div>

                <p>
                  We reserve the right to withdraw or amend our Service, and any
                  service or material we provide via Service, in our sole
                  discretion without notice. We will not be liable if for any
                  reason all or any part of Service is unavailable at any time
                  or for any period. From time to time, we may restrict access
                  to some parts of Service, or the entire Service, to users,
                  including registered users.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">23.</p>
                  <p className="privacy-policy-underline">
                    Amendments To Terms
                  </p>
                </div>

                <p>
                  We may amend Terms at any time by posting the amended terms on
                  this site. It is your responsibility to review these Terms
                  periodically.
                </p>

                <p>
                  Your continued use of the Platform following the posting of
                  revised Terms means that you accept and agree to the changes.
                  You are expected to check this page frequently so you are
                  aware of any changes, as they are binding on you.
                </p>

                <p>
                  By continuing to access or use our Service after any revisions
                  become effective, you agree to be bound by the revised terms.
                  If you do not agree to the new terms, you are no longer
                  authorized to use Service.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">24.</p>
                  <p className="privacy-policy-underline">
                    Waiver And Severability
                  </p>
                </div>

                <p>
                  No waiver by Company of any term or condition set forth in
                  Terms shall be deemed a further or continuing waiver of such
                  term or condition or a waiver of any other term or condition,
                  and any failure of Company to assert a right or provision
                  under Terms shall not constitute a waiver of such right or
                  provision.
                </p>

                <p>
                  If any provision of Terms is held by a court or other tribunal
                  of competent jurisdiction to be invalid, illegal or
                  unenforceable for any reason, such provision shall be
                  eliminated or limited to the minimum extent such that the
                  remaining provisions of Terms will continue in full force and
                  effect.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">25.</p>
                  <p className="privacy-policy-underline">Acknowledgement</p>
                </div>

                <p>
                  BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                  ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                  AGREE TO BE BOUND BY THEM.
                </p>

                <div className="privacy-policy-cont">
                  <p className="privacy-policy-num">26.</p>
                  <p className="privacy-policy-underline">Contact Us</p>
                </div>

                <p>
                  Please send your feedback, comments, requests for technical
                  support: <br /> By email: support@reaim.me.
                </p>
              </div>
            </div>

            <div className="contact-circle">
              <img
                src={circlepng}
                className="contact-circle-img"
                alt="circle"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
